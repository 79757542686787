<template>
  <v-container
    class="account"
    style="max-width: none;"
  >
    <v-row>
      <transition
        name="simple-slide-03"
        appear
      >
        <v-col style="padding: 0 !important;">
          <!-- todo edw to noumero server-items-length na einai dynamic -->
          <v-data-table
            :headers="tableHeaders"
            :items="getTableData()"
            item-key="name"
            class="elevation-1 orders-table goldair-table-generic-style"
            :server-items-length="itemsCount"
            :loading="loading"
            sort-by="oid"
            :sort-desc="true"
            @update:options="updateOptions"
          >
            <template v-slot:item.status="{ item }">
              <span
                class="status-after"
                :class="item.status.toLowerCase().replace(/\s/g , '_')"
              >{{ item.status }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <span class="actions">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      class="view"
                      @click="$router.push({ name: 'Order/View', params: { oid: item.oid } })"
                      v-on="on"
                    >
                      mdi-eye-outline
                    </v-icon>
                    <v-btn
                      v-if="getOrderPaymentType(item.oid) === 'ByCard' && !orderIsPaid(item.oid)"
                      class="pay-online-btn"
                      color="primary"
                      @click="payOrder(item.oid)"
                    >
                      <span>{{ $t('dataheaders.pay-order') }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t('dataheaders.view-order') }}</span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:item.tracking="{ item }">
              <span
                v-if="item.trackingNumber"
                class="tracking-number"
              >
                {{ item.trackingNumber }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <a
                      class="tracking-link"
                      :href="trackingUrl"
                      target="_blank"
                      v-on="on"
                    >
                      <v-img
                        src="@/assets/icons/track_icon.png"
                      />
                    </a>
                  </template>
                  <span>{{ $t('dataheaders.track-order') }}</span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:loading>
              <loader />
            </template>
          </v-data-table>
        </v-col>
      </transition>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import { dataMappers } from '@/store/helpers'
  import { loadCheckoutScript } from '../../store/modules/api-payment'
  import i18n from '@/i18n'

  export default {
    name: 'MyOrders',
    data: () => ({
      currentPage: [],
      loading: true,
      itemsCount: -1,
    }),
    computed: {
      ...mapState('orders', ['myOrders']),
      ...mapGetters('apiGeneric', ['getCountryDisplayNameByOid', 'getPackagingTypeNameById']),
      ...mapGetters('orders', ['getOrderByOid', 'getOrderPaymentType', 'orderIsPaid']),
      tableHeaders () {
        return [
          {
            text: i18n.t('dataheaders.orderid'),
            value: 'oid',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.cost'),
            value: 'cost',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.country-from'),
            value: 'countryFrom',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.postcode-from'),
            value: 'postCodeFrom',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.country-to'),
            value: 'countryTo',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.postcode-to'),
            value: 'postCodeTo',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.num-of-items'),
            value: 'numOfItems',
            align: 'center',
            sortable: false,
          },
          {
            text: i18n.t('dataheaders.created-date'),
            value: 'createdDate',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.options'),
            value: 'actions',
            align: 'center',
            sortable: false,
          },
          {
            text: i18n.t('dataheaders.status'),
            value: 'status',
            align: 'center',
            sortable: false,
          },
          {
            text: i18n.t('dataheaders.track-order'),
            value: 'tracking',
            align: 'center',
            sortable: false,
          },
        ]
      },
      trackingUrl: () => Vue.prototype.$orderTrackingUrl,
    },
    async created () {
      const ordersSummary = await this.$store.dispatch('orders/action_getOrdersSummary')
      this.itemsCount = ordersSummary.data.Total
      this.loading = false
    },
    methods: {
      ...mapActions('orders', ['action_getMyOrders']),
      ...mapActions('apiPayment', ['action_payOrderWithCard']),
      getStatusString: (code) => {
        return dataMappers.orderStatusCodeToStringMapper(code)
      },
      getTableData () {
        const data = []
        const orderObjects = this.getOrderByOid(this.currentPage)
        for (let i = 0; i < orderObjects.length; i++) {
          data.push(
            {
              oid: orderObjects[i].Oid,
              cost: (Math.round((orderObjects[i].Total + Number.EPSILON) * 100) / 100).toFixed(2),
              countryFrom: this.getCountryDisplayNameByOid(orderObjects[i].AddressFrom.Country.Oid),
              postCodeFrom: orderObjects[i].AddressFrom.PostCode,
              countryTo: this.getCountryDisplayNameByOid(orderObjects[i].AddressTo.Country.Oid),
              postCodeTo: orderObjects[i].AddressTo.PostCode,
              numOfItems: orderObjects[i].Items.length,
              status: this.getStatusString(orderObjects[i].Status),
              createdDate: moment(orderObjects[i].CreatedDate).format('HH:mm:ss DD/MM/YYYY'),
              trackingNumber: orderObjects[i].ShippingTrackingCode,
            },
          )
        }
        return data
      },
      getTableToApiLabel (tableLabel) {
        var apiLabel = ''
        switch (tableLabel) {
          case 'cost':
            apiLabel = 'Total'
            break
          case 'countryFrom': // todo den paizei, 8elei allagi sto api
            apiLabel = 'CountryFrom'
            break
          case 'postCodeFrom': // todo den paizei, 8elei allagi sto api
            apiLabel = 'PostCodeFrom'
            break
          case 'countryTo': // todo den paizei, 8elei allagi sto api
            apiLabel = 'CountryTo'
            break
          case 'postCodeTo': // todo den paizei, 8elei allagi sto api
            apiLabel = 'PostCodeTo'
            break
          case 'createdDate':
            apiLabel = 'CreatedDate'
            break
          default:
            apiLabel = tableLabel
        }
        return apiLabel
      },
      updateOptions: async function (options) {
        const payload = {
          params: {
            PageSize: options.itemsPerPage,
            Page: options.page,
          },
          skipCommit: true,
        }
        if (options.sortBy.length && options.sortDesc.length) {
          payload.params.OrderColumn = this.getTableToApiLabel(options.sortBy[0])
          payload.params.OrderAscending = !options.sortDesc[0]
        }
        // load callbacks before payment
        this.loadCheckoutScript()
        this.action_getMyOrders(payload).then((response) => {
          this.$store.commit('orders/SET_MY_ORDERS', response)
          this.currentPage = []
          for (let i = 0; i < response.data.length; i++) {
            this.currentPage.push(response.data[i].Oid)
          }
        })
          .finally(() => {
            this.loading = false
          })
      },
      payOrder: function (oid) {
        // console.log(oid)
        this.$store.commit('orders/SUCCESS_PAGE_ALLOW_ACCESS_ADD_OID', oid)
        this.action_payOrderWithCard({
          orderID: oid,
        })
      },
      // mono etsi mporw na tre3w ta parakatw functions pou eginan load apo to api-payment
      loadCheckoutScript: loadCheckoutScript,
    },
  }
</script>

<style lang="scss">
.container.account{
  .row{
    margin: 0;
  }

  .orders-table{
    box-shadow: none !important;
    width: 90%;
    background-color: $bgTransparent !important;
    @media all and (max-width: 750px){
      width: 100%;
    }

    .table-headers{
      margin-bottom: 10px;
    }

    table{
      tbody{
        tr{
          .create-order-btn{
            padding: 5px 10px;
            text-transform: capitalize;
          }
          td{
            font-size: 15px !important;
            &:first-child{
              position: relative;
              &:before{
                content: '#';
                position: absolute;
                top: 50%;
                transform: translate(-9px, -50%);
              }
            }
            span.actions {
              display: block;
              text-align: left;
              button.view{
                font-size: 19px;
              }
              .pay-online-btn {
                height: 31px;
                padding: 0 16px;
                text-transform: capitalize;
                margin-left: 5px;
              }
            }
            span.tracking-number{
              .tracking-link{
                display: inline-block;
                margin-left: 9px;
                box-sizing: content-box;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
}
</style>
