var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"account",staticStyle:{"max-width":"none"}},[_c('v-row',[_c('transition',{attrs:{"name":"simple-slide-03","appear":""}},[_c('v-col',{staticStyle:{"padding":"0 !important"}},[_c('v-data-table',{staticClass:"elevation-1 orders-table goldair-table-generic-style",attrs:{"headers":_vm.tableHeaders,"items":_vm.getTableData(),"item-key":"name","server-items-length":_vm.itemsCount,"loading":_vm.loading,"sort-by":"oid","sort-desc":true},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"status-after",class:item.status.toLowerCase().replace(/\s/g , '_')},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"view",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Order/View', params: { oid: item.oid } })}}},on),[_vm._v(" mdi-eye-outline ")]),(_vm.getOrderPaymentType(item.oid) === 'ByCard' && !_vm.orderIsPaid(item.oid))?_c('v-btn',{staticClass:"pay-online-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.payOrder(item.oid)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('dataheaders.pay-order')))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataheaders.view-order')))])])],1)]}},{key:"item.tracking",fn:function(ref){
var item = ref.item;
return [(item.trackingNumber)?_c('span',{staticClass:"tracking-number"},[_vm._v(" "+_vm._s(item.trackingNumber)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"tracking-link",attrs:{"href":_vm.trackingUrl,"target":"_blank"}},on),[_c('v-img',{attrs:{"src":require("@/assets/icons/track_icon.png")}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataheaders.track-order')))])])],1):_vm._e()]}},{key:"loading",fn:function(){return [_c('loader')]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }